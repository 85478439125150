<template>
  <v-container fluid>
    <UsersSectionSearch @search="() => $refs.table.refresh()" />

    <BaseTable
      ref="table"
      module="users"
      :headers="headers"
      :actions="actions"
      :dropdownactions="dropdownactions"
      :exportable="canUser('admin.users', 'download')"
      :dropdowncolor="dropdowncolor"
    >
      <template v-slot:item.username="{ item }">
        <v-chip label class="font-weight-medium">{{ item.username }}</v-chip>
      </template>
      <template v-slot:item.role="{ item }">
        {{ item.role ? item.role.label : '' }}
      </template>
      <template v-slot:item.group="{ item }">
        {{ item.group ? item.group.name : '' }}
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip :color="item.status === 'active' ? 'success' : 'gray'" small>
          {{ statuses_translations[item.status] }}
        </v-chip>
      </template>
      <template v-slot:item.last_access="{ item }">
        {{ item.last_access | momentOr('DD/MM/YYYY HH:mm', '-') }}
      </template>
    </BaseTable>

    <v-dialog
      v-model="dialog"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title>Modifica Dettagli</v-card-title>
        <v-card-text>
          <UsersForm :title="null" :edit="true" @submitted="onSubmitted" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog_confirm_state_update"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title>Richiesta conferma</v-card-title>
        <v-card-text>
          <h3 class="mt-3">
            Sei sicuro di voler
            {{ status === 'active' ? 'sospendere' : 'attivare' }} l'account
            {{ username }}?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="modalClose"> Annulla </v-btn>
          <v-btn color="primary" text @click="confirm_update">Conferma</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_confirm_reset_password"
      max-width="75%"
      :retain-focus="false"
      @click:outside="modalClose"
    >
      <v-card>
        <v-card-title>Richiesta conferma</v-card-title>
        <v-card-text>
          <h3 class="mt-3">
            Sei sicuro di voler resettare la password di {{ username }}?
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="modalClose"> Annulla </v-btn>

          <v-btn color="primary" text @click="confirm_reset_password">
            Conferma
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseTable from '@components/structure/base-table.vue'
import UsersSectionSearch from '@components/admin/users/UsersSectionSearch.vue'
import UsersForm from '@components/admin/users/UsersForm.vue'
import { createHelpers } from 'vuex-map-fields'
import { authComputed } from '@state/helpers.js'

const { mapFields } = createHelpers({
  getterType: 'users/getCurrent',
  mutationType: 'users/SET_CURRENT_FIELDS',
})

export default {
  name: 'UsersSearch',
  components: { UsersSectionSearch, BaseTable, UsersForm },
  page: {
    title: 'Cerca utenti',
  },
  data() {
    return {
      dialog: false,
      dialog_confirm_state_update: false,
      dialog_confirm_reset_password: false,
      headers: [
        // {
        //   text: 'Tipo',
        //   align: 'start',
        //   value: 'type',
        // },
        {
          text: 'Username',
          align: 'start',
          value: 'username',
        },
        {
          text: 'Cognome',
          value: 'last_name',
        },
        {
          text: 'Nome',
          value: 'first_name',
        },

        {
          text: 'Stato',
          value: 'status',
        },

        {
          text: 'Ruolo',
          value: 'role',
        },
        {
          text: 'Gruppo',
          value: 'group',
        },

        {
          text: 'Cliente',
          value: 'customer.common_name',
        },
        {
          text: 'Ultimo accesso',
          value: 'last_access',
        },
        {
          text: 'Creato',
          value: 'created_at',
        },
      ],
      actions: [
        {
          label: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          onItemCondition: () => this.canUser('admin.users', 'update'),
          handler: this.openModal,
        },
      ],
      dropdownactions: [
        {
          key: 'update_status',
          getLabel: (item) =>
            item.status === 'active' ? 'Sospendi' : 'Attiva',
          onItemCondition: (item) =>
            item?.role?.key &&
            item?.role?.key !== 'superadmin' &&
            this.canUser('admin.users', 'update'),
          handler: this.onStatusUpdate,
        },
        {
          key: 'reset_password',
          label: 'Reset password',
          onItemCondition: () => this.canUser('admin.users', 'update'),
          handler: this.onPasswordReset,
        },
      ],
      dropdowncolor: 'primary',
    }
  },
  computed: {
    ...authComputed,
    ...mapState('users', {
      statuses_translations: (state) => state.statuses_map_lang_it,
    }),
    ...mapFields(['status', 'username']),
  },
  mounted() {
    this.setScopes(['role', 'group', 'project', 'customer', 'bound_customers'])
  },
  beforeDestroy() {
    this.setScopes([])
  },
  methods: {
    ...mapActions('users', ['resetPassword', 'update']),

    ...mapMutations('users', {
      setScopes: 'SET_SCOPES',
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      const startingPoint = item.id ? item : {}
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.id
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },

    onSubmitted() {
      this.modalClose()
      this.$refs.table.refresh()
    },

    onStatusUpdate(item) {
      this.setCurrent(item)
      this.dialog_confirm_state_update = true
    },

    onPasswordReset(item) {
      this.setCurrent(item)
      this.dialog_confirm_reset_password = true
    },

    async confirm_update() {
      this.status = this.status === 'active' ? 'suspended' : 'active'
      await this.update()
      this.modalClose()
      this.$refs.table.refresh()
    },

    async confirm_reset_password() {
      this.resetPassword()
      this.modalClose()
    },

    modalClose() {
      this.dialog = false
      this.dialog_confirm_reset_password = false
      this.dialog_confirm_state_update = false
      this.resetCurrent()
    },
  },
}
</script>
