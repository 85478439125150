<template>
  <BaseSectionSearch @submit="onSubmit" @reset="onReset">
    <v-row dense>
      <FormItem
        v-model="username"
        type="text"
        name="Username"
        hint="Ricerca per username"
        dense
        :lg="3"
      />

      <FormItem
        v-model="type"
        type="autocomplete"
        :values="types"
        name="Tipo"
        hint="Ricerca per tipo"
        dense
        :lg="3"
      />

      <FormItem v-model="first_name" type="text" name="Nome" dense :lg="3" />

      <FormItem
        v-model="last_name"
        type="text"
        name="Cognome"
        hint="Ricerca per Cognome"
        dense
        :lg="3"
      />

      <FormItem
        v-model="email"
        type="text"
        name="Email"
        hint="Ricerca per Email"
        dense
        :lg="3"
      />
      <GroupsSelect v-model="group_id" dense :lg="3" />
      <RolesSelect v-model="role_id" dense :lg="3" />
    </v-row>
  </BaseSectionSearch>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapMutations, mapState } from 'vuex'

import BaseSectionSearch from '@components/structure/base-section-search.vue'
import FormItem from '@components/forms/FormItem.vue'
import GroupsSelect from '@components/admin/groups/GroupsSelect.vue'
import RolesSelect from '@components/admin/roles/RolesSelect.vue'

const { mapFields } = createHelpers({
  getterType: 'users/getFiltersFields',
  mutationType: 'users/SET_FILTER_FIELDS',
})

export default {
  name: 'UsersSectionSearch',
  components: {
    FormItem,
    BaseSectionSearch,
    GroupsSelect,
    RolesSelect,
  },
  computed: {
    ...mapFields([
      'username',
      'first_name',
      'last_name',
      'email',
      'type',
      'role_id',
      'group_id',
      'customer_id',
      'dealer_id',
    ]),
    ...mapState('users', ['filters', 'types']),
  },
  methods: {
    ...mapMutations('users', {
      reset: 'RESET_FILTER_FIELDS',
    }),
    onSubmit() {
      this.$emit('search')
    },
    onReset() {
      this.reset()
      this.$emit('search')
    },
  },
}
</script>

<style scoped></style>
