var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('UsersSectionSearch',{on:{"search":function () { return _vm.$refs.table.refresh(); }}}),_c('BaseTable',{ref:"table",attrs:{"module":"users","headers":_vm.headers,"actions":_vm.actions,"dropdownactions":_vm.dropdownactions,"exportable":_vm.canUser('admin.users', 'download'),"dropdowncolor":_vm.dropdowncolor},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"label":""}},[_vm._v(_vm._s(item.username))])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.role ? item.role.label : '')+" ")]}},{key:"item.group",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.group ? item.group.name : '')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.status === 'active' ? 'success' : 'gray',"small":""}},[_vm._v(" "+_vm._s(_vm.statuses_translations[item.status])+" ")])]}},{key:"item.last_access",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("momentOr")(item.last_access,'DD/MM/YYYY HH:mm', '-'))+" ")]}}])}),_c('v-dialog',{attrs:{"max-width":"75%","retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Modifica Dettagli")]),_c('v-card-text',[_c('UsersForm',{attrs:{"title":null,"edit":true},on:{"submitted":_vm.onSubmitted}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"75%","retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog_confirm_state_update),callback:function ($$v) {_vm.dialog_confirm_state_update=$$v},expression:"dialog_confirm_state_update"}},[_c('v-card',[_c('v-card-title',[_vm._v("Richiesta conferma")]),_c('v-card-text',[_c('h3',{staticClass:"mt-3"},[_vm._v(" Sei sicuro di voler "+_vm._s(_vm.status === 'active' ? 'sospendere' : 'attivare')+" l'account "+_vm._s(_vm.username)+"? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.modalClose}},[_vm._v(" Annulla ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.confirm_update}},[_vm._v("Conferma")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"75%","retain-focus":false},on:{"click:outside":_vm.modalClose},model:{value:(_vm.dialog_confirm_reset_password),callback:function ($$v) {_vm.dialog_confirm_reset_password=$$v},expression:"dialog_confirm_reset_password"}},[_c('v-card',[_c('v-card-title',[_vm._v("Richiesta conferma")]),_c('v-card-text',[_c('h3',{staticClass:"mt-3"},[_vm._v(" Sei sicuro di voler resettare la password di "+_vm._s(_vm.username)+"? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.modalClose}},[_vm._v(" Annulla ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.confirm_reset_password}},[_vm._v(" Conferma ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }